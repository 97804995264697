<template>
  <div>
    <v-row class="mt-10 px-5">
      <h2 class="mullerFont" style="font-size: 1.5rem">
        MES AVANTAGES EN TANT QUE {{ client_type }} {{ agency || company_name }}
      </h2>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row class="mt-n15">
      <v-col v-show="e1 < 9" cols="12" :md="e1 > 2 ? '9' : '12'" class="text-center">
        <v-stepper class="my-2" v-model="e1"></v-stepper>
        <v-card-title class="justify-center mt-8" style="color: #fcc03c !important">
          <v-row class="justify-center align-center">
            <v-col cols="12" md="2">
              <v-img
                contain
                width="200"
                height="150"
                :src="require(`@/assets/images/logos/anavel_logo.png`)"
                class="mx-auto"
              ></v-img>
            </v-col>
            <v-col cols="12" md="3">Les contrats éligibles</v-col>

            <v-col cols="12" md="2">
              <v-img width="200" height="150" contain :src="`${previewedUrl}`" class="mx-auto"></v-img>
            </v-col>
          </v-row>
        </v-card-title>
        <v-row justify="center">
          <v-col cols="12" md="8" class="text-center mt-n5">
            <v-card-text class="font-weight-bold"
              >Votre entreprise fait confiance à Anavel pour ses assurances. <br />
              C'est pourquoi votre employeur vous propose cette mise en relation afin de vous faire bénéficier d'offres
              adaptées à vos besoins et à des tarifs privilégiés
            </v-card-text>
          </v-col>
        </v-row>

        <v-row
          align="center"
          class="text-center align-center justify-center mb-5 mt-n2"
          v-if="contractsProposed.length > 0"
        >
          <v-row align="center" class="row-products align-center justify-center">
            <v-col
              cols="12"
              md="4"
              sm="6"
              class="col-products"
              v-for="item in contractsProposed"
              v-bind:key="item.name"
            >
              <v-card
                @mouseover="item.hovered = true"
                @mouseleave="item.hovered = false"
                v-on:click="addContracts(item)"
                outlined
                width="200"
                height="200"
                elevation="3"
                class="align-center d-flex rounded-xl"
              >
                <v-card-text class="text-center pa-5">
                  <i
                    :class="[
                      'mt-4 fa-solid ',
                      `fa-${item.icon}`,
                      'fa-5x',
                      item.hovered || contractsChoose.includes(item) ? 'EoleYellow--text' : 'EoleBlue--text',
                    ]"
                  ></i>
                  <p class="mt-2">{{ item.name }}</p>
                  <p
                    v-if="item.name === 'PER (PLAN D’ÉPARGNE RETRAITE INDIVIDUEL)'"
                    class="mt-n3 text-h6 EoleYellow--text"
                  >
                    Frais réduits
                  </p>
                  <p v-if="item.reduction > 0" class="mt-n3 text-h6 EoleYellow--text">-{{ item.reduction }}%</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
        <v-row v-else>
          <v-card-text>
            <i>
              <i class="fa-solid fa-triangle-exclamation fa-5x EoleYellow--text"></i>
            </i>
          </v-card-text>
          <v-card-text class="EoleYellow--text">
            Votre entreprise n'a pas encore établit de partenariat spécifique et donc aucun contrats n'est disponible.
          </v-card-text>
        </v-row>
        <v-card-subtitle class="mt-n6 font-italic" style="font-size: 0.75rem !important">
          Les remises sont exprimées par rapport au prix public des produits distribués par Anavel.
        </v-card-subtitle>
        <v-card-subtitle class="mt-n6" v-if="contractsProposed.length > 0 && contractsChoose.length === 0">
          Sélectionnez les contrats pour lesquels vous souhaitez obtenir un devis en cliquant sur les cartes.
        </v-card-subtitle>
        <v-row class="mt-n5" v-if="contractsProposed.length > 0">
          <v-col cols="12" md="12">
            <v-card-actions class="justify-center">
              <v-dialog v-model="dialog" max-width="800px" class="no-scrollbar" :persistent="waitSendedInformations">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="contractsChoose.length === 0"
                    color="EoleBlue"
                    class="white--text"
                    v-bind="attrs"
                    v-on="on"
                    >DEMANDER MES DEVIS</v-btn
                  >
                </template>

                <v-card class="no-scrollbar">
                  <v-card-title>
                    <span class="text-h5">Nouvelle demande</span>
                  </v-card-title>
                  <v-stepper class="transparent-bg" v-model="e1" flat>
                    <v-stepper-content :step="1" class="text-center transparent-bg">
                      <v-card-subtitle>Quelle est votre demande ? </v-card-subtitle>
                      <v-row class="text-center align-center d-inline-flex row-study mb-5 mt-5">
                        <v-col cols="12" md="6" v-for="item in appointmentType" v-bind:key="item.name">
                          <v-card
                            @mouseover="item.hovered = true"
                            @mouseleave="item.hovered = false"
                            v-on:click="
                              appointmentTypeChoose = item.value
                              e1 = item.e1
                            "
                            outlined
                            width="200"
                            height="200"
                            elevation="3"
                            class="align-center d-flex rounded-xl"
                          >
                            <v-card-text class="text-center pa-5">
                              <i
                                :class="[
                                  'mt-4 fa-solid ',
                                  item.icon,
                                  'fa-5x',
                                  item.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                                ]"
                              ></i>

                              <p class="mt-2">{{ item.name }}</p>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content
                      v-show="appointmentTypeChoose === 2"
                      :step="2"
                      class="text-center transparent-bg"
                    >
                      <v-card-subtitle>Quelle type de rendez-vous souhaitez-vous prendre ?</v-card-subtitle>
                      <v-row class="text-center align-center d-inline-flex row-agencies mb-5 mt-5">
                        <v-col cols="12" md="4" v-for="item in agenciesProposed" v-bind:key="item.name">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-card
                                @mouseover="item.hovered = true"
                                v-bind="attrs"
                                v-on="on"
                                @mouseleave="item.hovered = false"
                                v-on:click="
                                  agencyChoose = item.value
                                  e1++
                                "
                                outlined
                                width="200"
                                height="200"
                                elevation="3"
                                class="align-center d-flex rounded-xl"
                              >
                                <v-card-text class="text-center pa-5">
                                  <i
                                    :class="[
                                      'mt-4 fa-solid ',
                                      item.icon,
                                      'fa-5x',
                                      item.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                                    ]"
                                  ></i>

                                  <p class="mt-2">{{ item.name }}</p>
                                </v-card-text>
                              </v-card>
                            </template>
                            <span>{{ item.address }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content
                      v-if="appointmentTypeChoose === 2"
                      :step="3"
                      class="text-center transparent-bg no-scrollbar"
                    >
                      <v-card-subtitle>Ajoutez votre préférence de date et heures</v-card-subtitle>

                      <v-btn icon @click="previousWeek">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn icon @click="nextWeek">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-calendar
                        @click:event="showEvent"
                        @mousedown:time="startTime"
                        @mousemove:time="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseleave.native="cancelDrag"
                        :start="startDateCalendar"
                        style="overflow-y: hidden !important"
                        locale="fr"
                        :weekdays="[1, 2, 3, 4, 5]"
                        type="week"
                        v-model="selectedDate"
                        :now="today"
                        :events="events"
                        :event-margin="2"
                        :event-height="20"
                        :first-interval="17"
                        :interval-count="17"
                        :interval-minutes="30"
                      >
                        <template #event="{ event }">
                          <v-tooltip>
                            <template v-slot:activator="{ on, attrs }">
                              <v-card
                                v-bind="attrs"
                                v-on="on"
                                class="no-padding"
                                color="EoleYellow"
                                style="height: 100%; width: 100% !important"
                                outlined
                                elevation="0"
                              >
                                <v-card-title class="text-caption">{{ event.name }}</v-card-title>
                                <v-card-text class="text-caption no-padding">
                                  {{
                                    new Date(event.start).toLocaleString("fr-FR", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })
                                  }}
                                  -
                                  {{
                                    new Date(event.end).toLocaleString("fr-FR", { hour: "2-digit", minute: "2-digit" })
                                  }}
                                </v-card-text>
                              </v-card>
                            </template>
                            <span
                              >En ayant choisi {{ agencyChoose }} comme lieu de rendez-vous merci de vous présenter 30
                              minutes en avance...</span
                            >
                          </v-tooltip>
                        </template>
                      </v-calendar>
                    </v-stepper-content>

                    <v-stepper-content :step="4" class="text-center transparent-bg">
                      <v-card-text>
                        <v-form ref="formClientInformations" v-model="validityFormClientInformations" lazy-validation>
                          <v-row>
                            <v-col cols="12" md="3">
                              <v-select
                                :rules="civilityRules"
                                :items="genderItems"
                                v-model="genderClient"
                                label="Civilité"
                                prepend-inner-icon="mdi-gender-male-female"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                :rules="firstnameRules"
                                v-model="firstnameClient"
                                label="Prénom"
                                prepend-inner-icon="mdi-account"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                :rules="lastnameRules"
                                v-model="lastnameClient"
                                label="Nom"
                                prepend-inner-icon="mdi-account"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field
                                :rules="emailRules"
                                v-model="emailClient"
                                label="Email"
                                prepend-inner-icon="mdi-account"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                :rules="phoneRules"
                                v-model="phoneClient"
                                label="Téléphone"
                                prepend-inner-icon="mdi-account"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete
                                :loading="isLoading"
                                :search-input.sync="searchCity"
                                :items="cityItems"
                                v-model="cityClient"
                                :value="zipClient"
                                label="Commune"
                                clearable
                                hide-details
                                hide-selected
                                prepend-inner-icon="mdi-home"
                                no-filter
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-title> Rechercher une ville... </v-list-item-title>
                                  </v-list-item>
                                </template>

                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item }}</v-list-item-title>
                                  </v-list-item-content>
                                </template></v-autocomplete
                              >
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field
                                :rules="addressRules"
                                v-model="streetClient"
                                label="Adresse complète"
                                prepend-inner-icon="mdi-map-marker"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-stepper-content>
                  </v-stepper>

                  <v-card-actions>
                    <v-btn v-if="e1 > 1" text @click="appointmentTypeChoose === 1 ? (e1 = 1) : e1--">
                      <v-icon left>mdi-chevron-left</v-icon>
                      Précédent
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="appointmentTypeChoose === 2 && e1 === 3 && events.length === 1" text @click="e1 = 4">
                      Suivant
                      <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="e1 === 4"
                      text
                      color="EoleBlue"
                      @click="validateFormClientInformations"
                      :loading="waitSendedInformations"
                    >
                      <v-icon left>mdi-check</v-icon>
                      Confirmer mon rendez-vous
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue"
import "@mdi/font/css/materialdesignicons.css" // Load icons
import config from "@/views/config/config"
import pdfMake, { async } from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
pdfMake.vfs = pdfFonts.pdfMake.vfs
const PDFMerger = require("pdf-merger-js")
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import moment from "moment"
import { getCookie } from "@/utils/cookies"
moment.locale("fr")

var merger = new PDFMerger()
Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})

export default Vue.extend({
  name: "Home",

  data: () => ({
    waitSendedInformations: false,
    pictureProfil: null,
    guest_reduction: 0,
    idCustomer: null,
    contractsChoose: [],
    streetClient: "",
    cityClient: "",
    zipClient: "",
    emailClient: "",
    phoneClient: "",
    lastnameClient: "",
    firstnameClient: "",
    cityItems: [],
    genderClient: "",
    isLoading: false,
    validityFormClientInformations: false,
    genderItems: ["Monsieur", "Madame"],
    colors: ["#2196F3", "#3F51B5", "#673AB7", "#00BCD4", "#4CAF50", "#FF9800", "#757575"],
    names: ["Meeting", "Holiday", "PTO", "Travel", "Event", "Birthday", "Conference", "Party"],
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    today: "2023-05-25",
    selectedDate: null,
    events: [],
    startDateCalendar: "",
    calendarType: "week",
    selectedDates: "", // Tableau pour stocker les dates sélectionnées
    locale: "fr", // Langue du calendrier
    dialog: false,
    company_name: "",
    agency: "",
    client_type: null,
    agenciesProposed: [
      {
        name: "Rendez-vous téléphonique",
        value: "Téléphonique",
        address: "",
        icon: "fa-phone-volume",
        hovered: false,
      },
      {
        name: "Rendez-vous physique à l'agence de Rennes",
        value: "Rennes",
        address: "Avenue du 41ème régiment d'infanterie, 35000 Rennes",
        icon: "fa-building",
        hovered: false,
      },
      {
        name: "Rendez-vous physique à l'agence de Combourg",
        value: "Combourg",
        address: "1 Avenue des prunus, 35270 Combourg",
        icon: "fa-building",
        hovered: false,
      },
    ],
    typologyChoice: [
      {
        name: "PROFESSIONNEL",
        icon: "fa-building",
        hovered: false,
      },
      {
        name: "PARTICULIER",
        icon: "fa-people-arrows",
        hovered: false,
      },
    ],
    appointmentType: [
      {
        name: "Je souhaite être rappelé",
        value: 1,
        icon: "fa-phone",
        e1: 4,
        hovered: false,
      },
      {
        name: "Je souhaite prendre rendez-vous",
        value: 2,
        icon: "fa-solid fa-calendar-days",
        e1: 2,
        hovered: false,
      },
    ],
    appointmentTypeChoose: "",
    contactTypeChoose: "",
    contractsProposed: [
      // {
      //   name: "AUTOMOBILE",
      //   icon: "fa-car",
      //   hovered: false,
      // },
      // {
      //   name: "HABITATION",
      //   icon: "fa-home",
      //   hovered: false,
      // },
      // {
      //   name: "PROTECTION JURIDIQUE",
      //   icon: "fa-gavel",
      //   hovered: false,
      // },
      // {
      //   name: "SANTÉ",
      //   icon: "fa-hand-holding-heart",
      //   hovered: false,
      // },
      // {
      //   name: "ASSURANCE DE MES CRÉDITS",
      //   icon: "fa-piggy-bank",
      //   hovered: false,
      // },
      // {
      //   name: "GARANTIE ACCIDENTS DE LA VIE",
      //   icon: "fa-person-falling-burst",
      //   hovered: false,
      // },
    ],
    searchCity: null,
    lastnameClientRules: false,
    firstnameClientRules: false,
    genderClientRules: false,
    cityRules: [v => !!v || "Une ville est requise"],
    addressRules: [v => !!v || "Une adresse valide est requise"],
    civilityRules: [v => !!v || "Civilité est requise"],
    lastnameRules: [v => !!v || "Nom requis"],
    firstnameRules: [v => !!v || "Prénom requis"],
    lastnameRules: [v => !!v || "Nom requis"],
    dateRules: [v => !!v || "Date requis"],
    emailRules: [v => !!v || "E-mail requis", v => /.+@.+\..+/.test(v) || "L'email doit être valide"],
    phoneRules: [v => !!v || "Numéro de téléphone requis", v => /^\d{10}$/.test(v) || "Numéro de téléphone invalide"],
    //? SUCCE SS MESSAGES - PART
    successSelectCity: false,
    successSelectCityMessage: null,
    errorSelectCity: false,
    errorSelectCityMessage: null,
    middayStart: "",
    middayEnd: "",
    previewedUrl: "",
    e1: 1,
  }),
  components: {
    Loading,
  },
  watch: {
    loader() {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    },

    searchCity(val) {
      if (val.length === 5) {
        try {
          this.isLoading = true

          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.zipClient = val
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoading = false))

          // for (let index = 0; index < data.length; index++) {
          //   this.cityItems.push(data[index].nomCommune)
          // }

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }

      // // Items have already been loaded
      // if (this.items.length > 0) return

      // this.isLoading = true

      // // Lazily load input items
      // fetch('https://api.coingecko.com/api/v3/coins/list')
      //   .then(res => res.clone().json())
      //   .then(res => {
      //     this.items = res
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //   .finally(() => (this.isLoading = false))
    },

    otherAddress() {
      this.cityItems = []
    },
  },
  computed: {
    isDisabled() {
      if (this.zipClient.length < 5) return (this.zipClientOK = true)
    },
  },

  mounted() {},

  async created() {
    this.company_name = this.$store.state.company.commercial_name
    this.guest_reduction = localStorage.getItem("reduction")
    this.setCalendarType()
    this.setIntervalStyle()
    // this.getPictureProfil()
    this.fetchProductsProposed(this.$store.state.company.id)

    const state = this.$store.state.userRole

    this.previewedUrl = await this.previewFile(this.$store.state.company.img_url)

    if (state != "invite") {
      this.company_name = this.$store.state.company.name
    }

    //this.fetchInsurers()
  },
  methods: {
    async previewFile(path) {
      this.loadingCustomerProfile = true
      const idAgency = this.$store.state.company.id_agency
      let url
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/customers/documents/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            url: path,
          }),
        })

        if (response.ok) {
          const data = await response.blob()
          url = URL.createObjectURL(data)
        }
      } catch (error) {
        console.error(error)
      }
      return url
    },
    async fetchProductsProposed(idCustomer) {
      let idAgency
      if (this.$store.state.userRole === "invite") {
        idAgency = this.$store.state.company.id_agency
      } else {
        idAgency = this.$store.state.user.agency_id
      }
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/customers/${idCustomer}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          data.products.forEach(product => {
            this.contractsProposed.push({
              name: product.name.toUpperCase(),
              icon: product.icon,
              reduction: product.reduction,
              hovered: false,
            })
          })
          data.type == 1 ? (this.client_type = "COLLABORATEUR") : (this.client_type = "PARTENAIRE")
        } else {
        }
      } catch (e) {
        console.error(e)
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    getPictureProfil() {
      let agency = this.$store.state.company.agency_name.replace(/\s/g, "_") || "default"
      let name = this.$store.state.company.commercial_name || "default"

      try {
        let pictureProfilPng = `${config.ged}/${agency.toLowerCase()}/customers/${name
          .toLowerCase()
          .replace(/ /g, "_")}/files/profil.png?cache=${new Date().getTime()}`
        let pictureProfilJpg = `${config.ged}/${agency.toLowerCase()}/customers/${name
          .toLowerCase()
          .replace(/ /g, "_")}/files/profil.jpg?cache=${new Date().getTime()}`

        let img = new Image()
        img.onload = () => {
          this.pictureProfil = img.src
        }
        img.onerror = () => {
          this.pictureProfil = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.pictureProfil == null) {
            img.src = pictureProfilJpg
          }
        }, 500)
      } catch (error) {
        this.pictureProfil = null
      }

      // return url with picture profil
    },
    async createSponsoredAccount() {
      this.waitSendedInformations = true
      const idAgency = this.$store.state.company.id_agency
      const idCustomer = this.$store.state.company.id
      const data = {}
      let contracts = []

      this.contractsChoose.forEach(contract => {
        contracts.push({
          name: contract.name,
          icon: contract.icon,
          reduction: contract.reduction,
        })
      })
      if (this.appointmentTypeChoose === 2) {
        const eventStart = new Date(this.events[0].start)
        const eventEnd = new Date(this.events[0].end)

        // Ajouter deux heures à eventStart
        eventStart.setHours(eventStart.getHours() + 2)
        const updatedStartDateTime = eventStart.toISOString().slice(0, 19).replace("T", " ")

        // Ajouter deux heures à eventEnd
        eventEnd.setHours(eventEnd.getHours() + 2)
        const updatedEndDateTime = eventEnd.toISOString().slice(0, 19).replace("T", " ")

        data.dateStart = updatedStartDateTime
        data.dateEnd = updatedEndDateTime
      }

      data.civility = this.genderClient
      data.firstname = this.firstnameClient
      data.lastname = this.lastnameClient
      data.phone = this.phoneClient
      data.email = this.emailClient
      data.contracts = contracts
      data.appointmentType = this.appointmentTypeChoose

      this.appointmentTypeChoose === 2 && this.agencyChoose === "Téléphonique" ? (this.appointmentTypeChoose = 1) : 2
      this.appointmentTypeChoose === 2 ? (data.agency = this.agencyChoose) : null

      switch (this.appointmentTypeChoose) {
        case 1:
          data.appointmentType = "Téléphonique"

          if (this.agencyChoose === undefined) {
            data.appointment = 0

            let day = moment().add(2, "days").format("dddd")
            if (day === "samedi") {
              // add 2 days on day
              data.dateStart = moment().add(4, "days").format("YYYY-MM-DD HH:mm:ss")
              data.dateEnd = moment().add(4, "days").format("YYYY-MM-DD HH:mm:ss")
            } else if (day === "dimanche") {
              data.dateStart = moment().add(3, "days").format("YYYY-MM-DD HH:mm:ss")
              data.dateEnd = moment().add(3, "days").format("YYYY-MM-DD HH:mm:ss")
            } else {
              data.dateStart = moment().add(2, "days").format("YYYY-MM-DD HH:mm:ss")
              data.dateEnd = moment().add(2, "days").format("YYYY-MM-DD HH:mm:ss")
            }
          } else {
            data.appointment = 1
          }
          break
        case 2:
          data.appointmentType = "En agence"
          data.appointment = 1
          break
        default:
          data.appointmentType = "Téléphonique"
          break
      }

      try {
        const response = fetch(`${config.apiUri}/agencies/${idAgency}/customers/${idCustomer}/appointments`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify(data),
        }).then(response => {
          if (response.ok) {
            this.$toast.success(`Votre rendez-vous à bien été enregistré auprès de nos services.`, {
              position: "bottom-right",
              timeout: 3000,
            })
            this.waitSendedInformations = false

            this.dialog = false
            // this.sendmail()
          } else {
            this.waitSendedInformations = false

            this.$toast.success(`Impossible de créer de rendez-vous.`, {
              position: "bottom-right",
              timeout: 3000,
            })
            this.dialog = false
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    showEvent({ nativeEvent, event }) {
      // remove event
      this.events.splice(this.events.indexOf(event), 1)
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      } else if (this.events.length === 0) {
        this.createStart = this.roundTime(mouse)
        this.middayStart = this.roundTime(new Date(this.createStart).setHours(12, 0, 0, 0))
        this.middayEnd = this.roundTime(new Date(this.createStart).setHours(14, 0, 0, 0))

        // if hour is between 12:00 and 14:00
        if (this.createStart >= this.roundTime(this.middayStart) && this.createStart < this.roundTime(this.middayEnd)) {
          this.$toast.error(`Vous ne pouvez pas prendre rendez-vous sur cet horaire.`, {
            position: "top-right",
            duration: 5000,
            dismissible: true,
            pauseOnHover: true,
            closeOnClick: true,
            timeout: 3000,
          })
        } else {
          this.createEvent = {
            name: `Rendez-vous\nAnavel`,
            start: this.createStart,
            end: this.createStart + 60 * 60 * 1000,
            timed: true,
          }

          this.events.push(this.createEvent)
        }

        /// to locale
      } else {
        this.$toast.error(`Vous pouvez ajouter uniquement un seul rendez-vous.`, {
          position: "top-right",
          duration: 5000,
          dismissible: true,
          pauseOnHover: true,
          closeOnClick: true,
          timeout: 3000,
        })
      }
    },
    extendBottom(event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    endDrag(event) {
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = 30 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    // getEventColor (event) {
    //   const rgb = parseInt(event.color.substring(1), 16)
    //   const r = (rgb >> 16) & 0xFF
    //   const g = (rgb >> 8) & 0xFF
    //   const b = (rgb >> 0) & 0xFF

    //   return event === this.dragEvent
    //     ? `rgba(${r}, ${g}, ${b}, 0.7)`
    //     : event === this.createEvent
    //       ? `rgba(${r}, ${g}, ${b}, 0.7)`
    //       : event.color
    // },
    // getEvents ({ start, end }) {
    //   const events = []

    //   const min = new Date(`${start.date}T00:00:00`).getTime()
    //   const max = new Date(`${end.date}T23:59:59`).getTime()
    //   const days = (max - min) / 86400000
    //   const eventCount = this.rnd(days, days + 20)

    //   for (let i = 0; i < eventCount; i++) {
    //     const timed = this.rnd(0, 3) !== 0
    //     const firstTimestamp = this.rnd(min, max)
    //     const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000
    //     const start = firstTimestamp - (firstTimestamp % 900000)
    //     const end = start + secondTimestamp

    //     events.push({
    //       name: this.rndElement(this.names),
    //       color: this.rndElement(this.colors),
    //       start,
    //       end,
    //       timed,
    //     })
    //   }

    //   this.events = events
    // },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)]
    },

    getDateForDayOfWeek(dayOfWeek, time) {
      const today = new Date()
      const todayDayOfWeek = today.getDay()
      const distance = dayOfWeek - todayDayOfWeek
      const targetDate = new Date(today.setDate(today.getDate() + distance))
      return new Date(`${targetDate.toISOString().split("T")[0]}T${time}:00`)
    },

    setIntervalStyle() {
      this.intervalStyle = (date, time) => {
        if (time) {
          const hour = parseInt(time.split(":")[0])
          const minute = parseInt(time.split(":")[1])

          // Autoriser les créneaux de 9h à 12h et de 14h à 17h30
          if ((hour >= 9 && hour < 12) || (hour === 14 && minute === 0) || (hour === 17 && minute === 30)) {
            return {
              // backgroundColor: '#f5f5f5',
              // color: 'rgba(0, 0, 0, 0.6)',
              // pointerEvents: 'none'
            }
          }
        }

        // Désactiver les autres créneaux horaires
        return {
          display: "none",
        }
      }
    },
    updateCalendarType(date) {
      const formattedDate = date.toISOString().split("T")[0]
      const updatedStartDateCalendar = formattedDate // Variable temporaire pour stocker la valeur mise à jour
      // Autres opérations si nécessaire
      this.startDateCalendar = updatedStartDateCalendar // Assigner la valeur mise à jour à this.startDateCalendar
    },
    nextWeek() {
      const currentWeekMonday = new Date(this.startDateCalendar)

      const nextWeekMonday = new Date(
        currentWeekMonday.getFullYear(),
        currentWeekMonday.getMonth(),
        currentWeekMonday.getDate() + 7,
      )
      this.updateCalendarType(nextWeekMonday)
    },
    previousWeek() {
      const currentWeekMonday = new Date(this.startDateCalendar)
      const previousWeekMonday = new Date(
        currentWeekMonday.getFullYear(),
        currentWeekMonday.getMonth(),
        currentWeekMonday.getDate() - 7,
      )
      this.updateCalendarType(previousWeekMonday)
    },
    setCalendarType() {
      const today = new Date()
      const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
      const nextWeekMonday = new Date(nextWeek)
      nextWeekMonday.setDate(nextWeek.getDate() - nextWeek.getDay() + 2)
      // Setting the range of dates to display
      const formattedDate = nextWeekMonday.toISOString().split("T")[0]
      this.startDateCalendar = formattedDate
    },

    addContracts(item) {
      // add item in array contractsChoose if aleray exist remove it

      if (this.contractsChoose.includes(item)) {
        this.contractsChoose.splice(this.contractsChoose.indexOf(item), 1)
      } else {
        this.contractsChoose.push(item)
      }
    },
    validateFormClientInformations() {
      if (this.$refs.formClientInformations.validate()) {
        this.createSponsoredAccount()
      } else {
        this.$toast.error(`Veuillez remplir tous les champs obligatoire`, {
          position: "bottom-right",
          duration: 5000,
        })
      }
    },
  },
})
</script>
<style lang="scss">
// @media screen and (min-width: 768px) {
//   .col-product {
//     max-width: 50% !important;
//   }
// }

@media screen and (max-width: 700px) {
  .col {
    max-width: 100% !important;
  }
}
@media screen and (max-width: 1200px) {
  .col {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .col-products {
    max-width: 20%;
  }
}
.v-calendar:hover {
  cursor: pointer;
}
.testDivClass {
  align-content: center;
  justify-content: center;
  width: 200px;
}

.no-scrollbar ::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-calendar .v-calendar-daily .v-calendar-daily__events {
  background-color: white !important;
}

.v-calendar .v-event-timed {
  user-select: none;
  -webkit-user-select: none;
  background-color: white !important;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.mullerFont {
  font-family: muller;
  font-size: 1rem;
}

.card-title {
  font-size: 1.2rem;
  /* ajustez la taille de la police selon vos besoins */
  text-align: center;
  margin-top: 10px;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.row-products {
  max-width: 70%;
}

.row-study {
  max-width: 450px;
}

.row-agencies {
  max-width: 600px;
}

.title {
  color: #fcc03c !important;
}

.no-padding {
  padding: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
}

.v-switch {
  color: red;
}

.v-slider {
  font-size: 10px;
}

.transparent-bg {
  background-color: transparent !important;
}
</style>
